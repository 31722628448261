export const SET_IS_MONOPOLY_SUBJECT = 'SET_IS_MONOPOLY_SUBJECT';
export const SET_MAP = 'SET_MAP';
export const SET_REGISTERS = 'SET_REGISTERS';
export const SET_NETWORKS = 'SET_NETWORKS';
export const SET_REGISTRY = 'SET_REGISTRY';
export const SET_REGISTRY_DATA = 'SET_REGISTRY_DATA';
export const SET_PAGE = 'SET_PAGE';
export const SET_FILTER_CATALOG_LIST = 'SET_FILTER_CATALOG_LIST';
export const SET_FILTER_DATE_VALUE = 'SET_FILTER_DATE_VALUE';
export const SET_FILTER_CATALOG_VALUE = 'SET_FILTER_CATALOG_VALUE';
export const SET_FILTER_TEXT_VALUE = 'SET_FILTER_TEXT_VALUE';
export const SET_FILTER_RANGE_VALUE = 'SET_FILTER_RANGE_VALUE';
export const SET_SORT_PARAMS = 'SET_SORT_PARAMS';
export const SET_REGISTRY_LOADER = 'SET_REGISTRY_LOADER';
export const SET_MAP_OBJECT = 'SET_MAP_OBJECT';
export const SET_IS_SHOW_OBJECT_PANEL = 'SET_IS_SHOW_OBJECT_PANEL';
export const SET_IS_SHOW_GEOMETRY_UPDATE_PANEL = 'SET_IS_SHOW_GEOMETRY_UPDATE_PANEL';
