export default () => ({
  isMonopolySubject: false,
  map: null,
  originalRegistryList: [],
  networkList: [],
  registryList: [],
  selectedRegistry: null,
  registryData: {},
  page: 1,
  isRegistryDataLoaderState: false,
  catalogListForFilter: {},
  dateColValueForFilter: {},
  rangeColValueForFilter: {},
  textColValueForFilter: null,
  textColForFilter: null,
  catalogColValueForFilter: {},
  sortParams: {},
  registryOlLayer: null,
  selectedMapObject: null,
  isShowObjectPanel: false,
  isShowGeometryUpdatePanel: false,
});
