import olInitMap from "~/assets/js/olInitMap";
import Vue from "vue";
import TileLayer from "ol/layer/Tile.js";
import TileWMS from "ol/source/TileWMS.js";
import { 
  FETCH_IS_MONOPOLY_SUBJECT,
  INIT_MAP,
  GET_REGISTERS,
  LOAD_REGISTRY_DATA,
  SAVE_OBJECT_CHANGES
} from "./action-types";
import {
  SET_IS_MONOPOLY_SUBJECT,
  SET_REGISTERS,
  SET_NETWORKS,
  SET_REGISTRY_DATA,
  SET_PAGE,
  SET_REGISTRY_LOADER,
  SET_FILTER_CATALOG_LIST,
  SET_MAP,
} from "./mutation-types";
const createLayer = (layer) =>
  new TileLayer({
    source: new TileWMS({
      url: `${process.env.GEOSERVER_URL}/wms`,
      params: {
        LAYERS: layer,
        TILED: true,
        VERSION: "1.1.1",
        SRS: "EPSG:3857",
        BBOX: "{bbox-epsg-3857}",
        WIDTH: 256,
        HEIGHT: 256,
      },
      serverType: "geoserver",
    }),
    zIndex: 97,
  });

export default {
  [FETCH_IS_MONOPOLY_SUBJECT]({ commit }) {
    return this.$axios.$get("api/sem/check-access").then((response) => {
      commit(SET_IS_MONOPOLY_SUBJECT, response == 1);
    });
  },
  async [INIT_MAP]({ commit }, { el }) {
    let map = olInitMap({ el });
    map.getView().setZoom(5);
    commit(SET_MAP, map);
  },
  [GET_REGISTERS]({ commit }) {
    return this.$axios
      .$get("api/sem/registries")
      .then((response) => {
        commit(SET_REGISTERS, response);
        let networks = response
          .map((item) => item.category)
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.kk === value.kk && t.ru === value.ru)
          );
        commit(SET_NETWORKS, networks);
      })
      .catch(() => alert("Ошибка загрузки реестров"));
  },
  async [LOAD_REGISTRY_DATA]({ commit, state }, { page }) {
    commit(SET_PAGE, page);
    commit(SET_REGISTRY_LOADER, true);
    let params = {
      table: state.selectedRegistry.table_name,
      page: page,
    };
    let hasFilterByCatalog = Object.keys(state.catalogColValueForFilter).length;
    let hasFilterByDate = false;
    Object.keys(state.dateColValueForFilter).forEach((key) => {
      let length = state.dateColValueForFilter[key].length;
      if (length) hasFilterByDate = true;
    });
    let hasFilterRange = false;
    Object.keys(state.rangeColValueForFilter).forEach((key) => {
      let length = state.rangeColValueForFilter[key].length;
      if (length) hasFilterRange = true;
    });
    if (state.textColValueForFilter || hasFilterByCatalog || hasFilterByDate || hasFilterRange) {
      params["search"] = {};
    }
    if (state.textColValueForFilter) {
      Vue.set(
        params["search"],
        state.textColForFilter,
        state.textColValueForFilter
      );
    }
    if (hasFilterByCatalog) {
      let catalogs = {};
      Object.keys(state.catalogColValueForFilter).forEach((key) => {
        if (state.dateColValueForFilter[key]) {
          catalogs[key] = state.dateColValueForFilter[key];
        }
      });
      params["search"] = {
        ...params["search"],
        ...catalogs,
      };
    }
    if (hasFilterByDate) {
      let dates = {};
      Object.keys(state.dateColValueForFilter).forEach((key) => {
        let length = state.dateColValueForFilter[key].length;
        if (length) {
          dates[key] = state.dateColValueForFilter[key];
        }
      });
      params["search"] = {
        ...params["search"],
        ...dates,
      };
    }
    if (hasFilterRange) {
      let ranges = {};
      Object.keys(state.rangeColValueForFilter).forEach((key) => {
        let length = state.rangeColValueForFilter[key].length;
        if (length) {
          ranges[key] = state.rangeColValueForFilter[key];
        }
      });
      params["search"] = {
        ...params["search"],
        ...ranges,
      };
    }
    if (Object.keys(state.sortParams).length) {
      params["sort"] = state.sortParams;
    }
    return this.$axios
      .$get("api/sem/data", { params })
      .then((response) => {
        let catalogs = {};
        let catalogCols = response.columns.filter((col) => {
          return col.search_type === "catalog";
        });
        catalogCols.forEach((col) => {
          catalogs[col.id] = col.catalog_values;
        });
        commit(SET_FILTER_CATALOG_LIST, catalogs);
        commit(SET_REGISTRY_DATA, response);
      })
      .catch(() => alert("Ошибка загрузки данных реестра"))
      .finally(() => {
        commit(SET_REGISTRY_LOADER, false);
      });
  },
  async [SAVE_OBJECT_CHANGES]({ state }, { pk, object }) {
    const params = {
      table: state.selectedRegistry.table_name,
      pk: pk,
      data: object
    };
    return this.$axios.$post("api/sem/edit", params);
  },
};
