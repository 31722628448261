//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      authModalShow: false,
      forgotPassShow: false,
      envUrl: process.env.url
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
  },
  methods: {
    goToFeedback() {
      let params = {
        locale: this.currentLocale.code,
      };
      if (this.$auth.loggedIn) {
        this.$axios
          .$get("/api/feedback", { params })
          .then((resp) => this.$router.push(`/base/service/info/` + resp));
      } else {
        this.authModalShow = true;
      }
    },
  },
};
