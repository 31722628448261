import {
  SET_IS_MONOPOLY_SUBJECT,
  SET_MAP,
  SET_REGISTERS,
  SET_NETWORKS,
  SET_REGISTRY,
  SET_PAGE,
  SET_FILTER_CATALOG_LIST,
  SET_FILTER_DATE_VALUE,
  SET_SORT_PARAMS,
  SET_FILTER_CATALOG_VALUE,
  SET_FILTER_TEXT_VALUE,
  SET_REGISTRY_DATA,
  SET_REGISTRY_LOADER,
  SET_MAP_OBJECT,
  SET_IS_SHOW_OBJECT_PANEL,
  SET_FILTER_RANGE_VALUE,
  SET_IS_SHOW_GEOMETRY_UPDATE_PANEL
} from './mutation-types';

export default {
  [SET_IS_MONOPOLY_SUBJECT](state, isMonopolySubject) {
    state.isMonopolySubject = isMonopolySubject;
  },
  [SET_MAP](state, map) {
    state.map = map;
  },
  [SET_REGISTERS](state, list) {
    state.originalRegistryList = list;
  },
  [SET_NETWORKS](state, list) {
    state.networkList = list;
  },
  [SET_PAGE](state, page) {
    state.page = page;
  },
  [SET_REGISTRY](state, registry) {
    state.selectedRegistry = registry;
  },
  [SET_REGISTRY_DATA](state, data) {
    state.registryData = data;
  },
  [SET_FILTER_CATALOG_LIST](state, value) {
    state.catalogListForFilter = value;
  },
  [SET_FILTER_DATE_VALUE](state, value) {
    state.dateColValueForFilter = value;
  },
  [SET_SORT_PARAMS](state, value) {
    state.sortParams = value;
  },
  [SET_FILTER_CATALOG_VALUE](state, value) {
    state.catalogColValueForFilter = value;
  },
  [SET_FILTER_RANGE_VALUE](state, value) {
    state.rangeColValueForFilter = value;
  },
  [SET_FILTER_TEXT_VALUE](state, value) {
    state.textColForFilter = value.col;
    state.textColValueForFilter = value.value;
  },
  [SET_REGISTRY_LOADER](state, value) {
    state.isRegistryDataLoaderState = value;
  },
  [SET_MAP_OBJECT](state, value) {
    state.selectedMapObject = value;
  },
  [SET_IS_SHOW_OBJECT_PANEL](state, value) {
    state.isShowObjectPanel = value;
  },
  [SET_IS_SHOW_GEOMETRY_UPDATE_PANEL](state, value) {
    state.isShowGeometryUpdatePanel = value;
  }
}
